import React from 'react';

const IconError = ({ className, iconColor="#ffffff" }) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9924 23.1407C16.5037 23.1407 16.935 22.9677 17.2864 22.6217C17.6375 22.2757 17.813 21.8469 17.813 21.3353C17.813 20.8238 17.64 20.3893 17.294 20.032C16.948 19.6744 16.5193 19.4957 16.0077 19.4957C15.4964 19.4957 15.065 19.6732 14.7137 20.0283C14.3626 20.3834 14.187 20.8168 14.187 21.3283C14.187 21.8399 14.36 22.2698 14.706 22.618C15.052 22.9664 15.4808 23.1407 15.9924 23.1407ZM16.0167 17.371C16.4743 17.371 16.8692 17.2048 17.2014 16.8723C17.5338 16.5401 17.7 16.1452 17.7 15.6877V10.7543C17.7 10.2968 17.5338 9.90189 17.2014 9.56966C16.8692 9.23722 16.4743 9.071 16.0167 9.071C15.5592 9.071 15.1643 9.23722 14.832 9.56966C14.4996 9.90189 14.3334 10.2968 14.3334 10.7543V15.6877C14.3334 16.1452 14.4996 16.5401 14.832 16.8723C15.1643 17.2048 15.5592 17.371 16.0167 17.371ZM16 29.6407C14.1016 29.6407 12.3242 29.2826 10.6677 28.5663C9.01126 27.8503 7.57026 26.8786 6.34471 25.651C5.11938 24.4234 4.14882 22.9822 3.43304 21.3273C2.71726 19.6724 2.35938 17.8967 2.35938 16C2.35938 14.1016 2.71749 12.3241 3.43371 10.6677C4.14971 9.01122 5.12149 7.57022 6.34904 6.34466C7.5766 5.11933 9.01782 4.14877 10.6727 3.43299C12.3276 2.71722 14.1034 2.35933 16 2.35933C17.8985 2.35933 19.6759 2.71744 21.3324 3.43366C22.9888 4.14966 24.4298 5.12144 25.6554 6.34899C26.8807 7.57655 27.8513 9.01777 28.567 10.6727C29.2828 12.3276 29.6407 14.1033 29.6407 16C29.6407 17.8984 29.2826 19.6759 28.5664 21.3323C27.8504 22.9888 26.8786 24.4298 25.651 25.6553C24.4235 26.8807 22.9823 27.8512 21.3274 28.567C19.6725 29.2828 17.8967 29.6407 16 29.6407ZM16 26.274C18.872 26.274 21.3024 25.2797 23.291 23.291C25.2797 21.3023 26.274 18.872 26.274 16C26.274 13.128 25.2797 10.6977 23.291 8.709C21.3024 6.72033 18.872 5.726 16 5.726C13.128 5.726 10.6977 6.72033 8.70904 8.709C6.72037 10.6977 5.72604 13.128 5.72604 16C5.72604 18.872 6.72037 21.3023 8.70904 23.291C10.6977 25.2797 13.128 26.274 16 26.274Z" fill="#EF312A"/>
        </svg>
    );
};

export default IconError;
