import React, { useState, useEffect, useRef } from 'react';
import countryDataStatic from '../../static/counry_code.json';

const PhoneNumberInput = ({phoneNumber,setPhoneNumber,selectedCountry,setSelectedCountry}) => {
  const [countryData, setCountryData] = useState(countryDataStatic);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setFocusedIndex(-1); // Reset focus index upon opening
    if (!isDropdownOpen) {
      setSearchTerm('');
    }
  };

  const handleOptionSelect = (country) => {
    setSelectedCountry(country);
    setIsDropdownOpen(false);
    setFocusedIndex(-1);
  };

  const handleOutsideClick = (event) => {
    if (!dropdownRef.current?.contains(event.target) && !inputRef.current?.contains(event.target)) {
      setIsDropdownOpen(false);
      setFocusedIndex(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setFocusedIndex(-1); // Reset focus index when search changes
  };

  const handleKeyDown = (e) => {
    if (!isDropdownOpen) return;

    const maxIndex = filteredCountries.length - 1;
    if (e.key === "ArrowDown") {
      setFocusedIndex(prevIndex => (prevIndex < maxIndex ? prevIndex + 1 : prevIndex));
    } else if (e.key === "ArrowUp") {
      setFocusedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === "Enter" && focusedIndex >= 0) {
      handleOptionSelect(filteredCountries[focusedIndex]);
    }
  };

  const filteredCountries = searchTerm ? countryData.filter(country => 
    country.name.toLowerCase().includes(searchTerm.toLowerCase()) || country.dial_code.includes(searchTerm)
  ) : countryData;

  useEffect(() => {
    const listItem = dropdownRef.current?.querySelector(`li:nth-child(${focusedIndex + 1})`);
    listItem?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }, [focusedIndex]);

  return (
    <div className="relative w-full max-sm:w-8/12 max-sm:m-auto max-sm:my-4 sm:w-1/2 mt-4" onKeyDown={handleKeyDown}>
      <div className="relative mt-2">
        <button ref={inputRef} onClick={toggleDropdown} className="absolute inset-y-0 left-0 flex items-center pl-3 text-base text-black/80 bg-transparent focus:outline-none font-bold">
          <span>{selectedCountry.emoji} {selectedCountry.dial_code}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 9L12 16.5 4.5 9" />
          </svg>
        </button>
        {isDropdownOpen && (
          <div ref={dropdownRef} className="absolute left-0 w-full bg-white border border-slate-200 rounded-md shadow-lg z-10">
            <input type="text" placeholder="Search by country name" value={searchTerm} onChange={handleSearchChange} className="w-full p-2 border-b border-slate-200 bg-white/20 outline-none" />
            <ul className="max-h-40 overflow-y-auto">
              {filteredCountries.map((country, index) => (
                <li key={country.code} 
                    className={`px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center ${index === focusedIndex ? 'bg-slate-100' : ''}`}
                    onClick={() => handleOptionSelect(country)}>
                  <span className="mr-2">{country.emoji}</span>
                  {country.name.length >= 15 ? country.name.slice(0, 15) + '...' : country.name} ({country.dial_code})
                </li>
              ))}
            </ul>
          </div>
        )}
        <input
          type="tel"
          id="tel"
          name="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="w-full pl-28 pr-3 py-2 bg-transparent text-black/80 border rounded-md transition duration-300 border-gray-300 ease focus:outline-none focus:border-indigo-500 text-base hover:border-slate-300 shadow-sm focus:shadow ease-in-out placeholder-black/80 placeholder:font-bold font-bold"
          placeholder="Enter phone number *"
          pattern="[0-9]*"
          inputMode="numeric"
          maxLength="15"
          autoComplete="off"
          minLength={selectedCountry.dial_code.length + 5}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;