// UserProvider.js
import React, { useState, useContext } from 'react';
import UserContext from './UserContext';

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({pathname: '/'});

  const toggleUser = (pathname) => {
    setUser({pathname : pathname});
  };

  return (
    <UserContext.Provider value={{ user, toggleUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useContextUser = () => {
    return useContext(UserContext);
};
  

export { UserProvider, useContextUser };