import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./TicketDetails.css";
import { useNavigate } from "react-router-dom";
import { useContextUser } from "../../components/context/UserProvider";

const TicketDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [attempt, setAttempt] = useState(0);
  const production = true;
  const [error, setError] = useState(null);
  const { user, toggleUser} = useContextUser();
  const { pathname } = user;

  useEffect(() => {
    if (!pathname.includes("ticketgenerate")) {
      // Clear forward history
      window.history.pushState(null, "", "/");
      // Prevent going back
      window.history.pushState(null, "", "/");
      navigate("/", { replace: true });
    }
  }, [pathname]);

  const navigate = useNavigate();

  const baseUrl = "https://qwaiting.com/restapi";
  const restApiKey = "fe14a6-00d1ae-8126d0-bfe34b-0fdd9a";

  // ticket Details
  const ticketDetailData = async (ticketId) => {
    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append("ticketId", ticketId);

      const headers = {
        "Content-Type": "multipart/form-data",
        "rest-api-key": restApiKey,
      };
      const response = await axios.post(
        `${baseUrl}/ticket_detail/${id}`,
        formData,
        { headers }
      );

      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.log(error);
      console.error("There was an error fetching the data!", error);
    }
  };
  useEffect(() => {
    // 2226940
    ticketDetailData(id);
  }, [id]);
  // http://localhost:3000/ticketgenerate/2713209
  useEffect(() => {
    // call api or anything
    if(data?.ticket){
      console.log("loaded");
      console.log(data?.ticket);
     if(production && !attempt){
      window.print();
      document.getElementById("printablediv").style.display="none"
      setAttempt(1);
      setTimeout(()=>{
        navigate("/", { replace: true });
      }, 500)
     }     
    }

 },[data]);

 function testPrint(){
  window.print();
 }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data?.ticket) {
     return <div>Loading...</div>;
  }

  return (
    <>
      <div className="container" id="printablediv">
        <div className="header">
          <div>
            <img
              src="https://qwaiting.com/images/logo/1691999247.png"
              alt="Image"
              width="100"
              style={{ margin: "0 auto" }}
            />
            <div className="title">{data?.name}</div>
          </div>
        </div>
        <div className="queue-info">Queue No.</div>
        <div className="highlight">{data?.ticket.Call.token}</div>
        <div className="queue-info">Arrived: {data?.ticket.Call.created}</div>
        <div className="title">{data?.ticket.Cat1.name}</div>
        {data?.ticket?.Cat2?.name && (
          <div className="title">{data?.ticket.Cat2.name}</div>
        )}
        {data?.ticket?.Cat3?.name && (
          <div className="title">{data?.ticket.Cat3.name}</div>
        )}

        <div className="queue-info">
          You are {data?.ticket.Call.queue_count} in the queue.
        </div>
        {/* <div className="queue-info">Your Estimated waiting time is 0 Min.</div> */}
        {/* <hr style="width: 50%;" /> */}
        <div>
          <div className="button">Thank you</div>
          <button className="button" onClick={() => navigate("/")}>
            Home
          </button>
        </div>
      </div>

     {!production && <button onClick={testPrint}>TEST Print</button>}

    </>
  );
};

export default TicketDetails;
