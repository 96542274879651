import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import IconCamera from '../Icons/icon-camera';
import io from 'socket.io-client';
import PhoneNumberInput from './PhoneNumberInput';

const Form = () => {
    const [selectedCountry, setSelectedCountry] = useState({ emoji: '🇮🇳', dial_code: '+91' });
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [btnIndex, setBtnIndex] = useState(null);
    const navigate = useNavigate();
    const namePattern = /^[A-Za-z\s]+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{5,15}$/;
    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = io('https://ebitsvisionai.in', {
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: 5
        });

        socketRef.current.on('connect', () => {
           console.log("Client connected to server");
        });

        socketRef.current.on('disconnect', () => {
            console.log("Client disconnected from server");
        });

        socketRef.current.on('validation_result', (data) => {
            const { name, email, phone } = data;
            if (data.exists) {
                toast.error(data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                // Navigate to /face-register with payload
                navigate('/face-register', { state: { name, email, phone } });
            }
        });

    }, []);

    const onSubmit = () => {

        if (name.trim() === "" || email.trim() === "" || phoneNumber.trim() === "") {
            setName(name.trim());
            toast.error('All fields are required' , {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        
        if (!namePattern.test(name)) {
            toast.error('Name can only contain letters and spaces' , {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (!emailPattern.test(email)) {
            toast.error('Please enter a valid email address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (!phonePattern.test(selectedCountry.dial_code.replace(/^\+/, '') + phoneNumber)) {
            toast.error('A phone number requires between 5 and 15 digits.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        let temp_phone = selectedCountry.dial_code + phoneNumber
        
        socketRef.current.emit('check_phone_email_exist', { name, email, phone: temp_phone });
    };

    const recognised = () => {
        navigate('/face-recognisation');
    }

    return (
        <>
            {
                btnIndex !== 1 &&
                <img
                    src={require("../../assets/waiting.png")}
                    alt="background"
                    className='max-w-3l my-5 object-cover md:max-w-3xl'
                />
            }


            {
                btnIndex !== 1 &&
                <div className='space-x-2 flex flex-wrap sm:w-1/2 lg:w-2/6 md:w-2/6 max-sm:w-full justify-evenly my-10'>
                    <button
                        onClick={() => setBtnIndex(1)}
                        className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Register
                    </button>
                    <button
                        onClick={() => recognised()}
                        className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Recognise
                    </button>
                </div>
            }

            {
                btnIndex === 1 &&
                <div className="flex w-1/4 my-7 flex-col space-y-4 items-center justify-evenly sm:w-full lg:w-2/4 md:w-2/3 max-sm:w-full ">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="max-sm:w-8/12 font-bold max-sm:m-auto max-sm:my-4 w-full sm:w-1/2 bg-transparent rounded border bg-opacity-50 border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-black/80 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out placeholder-black/80 placeholder:font-bold"
                        placeholder="Enter Name *"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="off"
                    />

                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="max-sm:w-8/12 sm:w-1/2 w-full font-bold max-sm:m-auto max-sm:my-4 bg-transparent rounded border bg-opacity-50 border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-black/80 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out placeholder-black/80 placeholder:font-bold"
                        placeholder="Enter Email *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                    />

                    <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}/>

                        <div 
                            className='sm:space-x-2 max-sm:w-8/12 sm:w-1/2 w-full flex flex-col sm:flex-row flex-wrap max-sm:m-auto  justify-between items-center h-24'
                        >

                            <button 
                                className="inline-flex text-white bg-blue-700 border-0 py-1.5 px-3 max-sm:px-2 max-sm:text-[1rem] max-sm:m-auto focus:outline-none hover:bg-blue-600 rounded text-lg"
                                onClick={()=> setBtnIndex(null)}
                            >
                                <span className='mx-3 max-sm:text-sm'>Back</span>
                            </button>
                            <button 
                                className="inline-flex text-white bg-blue-700 border-0 py-1.5 px-3 max-sm:px-2 max-sm:text-[1rem] max-sm:m-auto focus:outline-none hover:bg-blue-600 rounded text-lg" 
                                onClick={onSubmit}
                            >
                                <div className='flex justify-between items-center'>
                                    <IconCamera className="text-white max-sm:text-sm" iconColor="#ffffff" />
                                    <span className='mx-3 max-sm:text-sm'>Start Capturing</span>
                                </div>
                            </button>
                        </div>
                </div>
            }

            {
                btnIndex === 1 &&
                 <h1 className='font-bold mt-5 text-sm sm:text-lg sm:max-w-5xl text-black/80 max-w-3xl mx-10'>
                  <span className='text-red-700/80 font-extrabold'>Note:-</span> Please keep your face in the centre of the camera. The registration process will take only 8 seconds.To ensure full access to the system, a camera must be integrated and operational within your setup. Please enable access to the camera as it is essential for complete system functionality.
                 </h1>
             }
        </>
    )
}

export default Form